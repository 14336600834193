import React, { useEffect, useState, useRef } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import PageHero from "../components/template-partials/page-hero"
import FooterCrown from "../components/template-partials/footer-crown"
import InputMask from "react-input-mask"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDown,
  faAngleRight,
  //   faExternalLinkAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import QuestionsSM from "../components/template-partials/questions-sm"
//import linkOutIcon from "../images/link_out_bold.svg"
import hrImage from "../images/hr-image.svg"

const QuestionsSMBlockButtons = [
  { text: "VIEW FAQs", to: "/sm-resources/faqs/", arrow: true },
]

const SignUpPage = () => {
  const checkboxRef = useRef(null)
  const [complete, setIsComplete] = useState(false)
  const [phone, setPhone] = useState()
  const [choice, setChoice] = useState()
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked)
  }

  useEffect(() => {
    const hsh = typeof window !== "undefined" ? window.location.hash : ""
    const hv = hsh.split("#")[1] === "complete" ? true : false
    setIsComplete(hv)
  }, [])

  useEffect(() => {
    const checkbox = checkboxRef.current
    if (checkbox) {
      const isChecked = checkbox.checked
    }
  }, [isChecked])

  return (
    <Layout route="sign-up">
      <Seo
        page="resources"
        title="Sign Up for Information"
        description="Sign up to receive updates on the latest information about systemic mastocytosis (SM)."
      />

      <div className="row lg_mt-3 mt-1 align-center medium-align-left">
        <div className="columns small-12 large-12">
          <h1 className="color--purple text--watch-quinn">
            Stay up to date on information about systemic mastocytosis (SM) and
            its treatment
          </h1>
        </div>
      </div>

      {!complete && (
        <div className="row lg_mt-2 mt-2 align-center medium-align-left">
          <div className="columns small-12 medium-expand">
            <p className="h2 color--purple strong">
              Sign up to be the first to know
            </p>
            <p className="bodyP color--purple mt-4">
              All fields required unless otherwise noted.
            </p>
          </div>
        </div>
      )}

      {complete && (
        <div className="row lg_mt-4 mt-2 align-center medium-align-left">
          <div className="columns small-12 medium-expand">
            <p className="h3 color--purple strong">
              Your information has been successfully submitted.
            </p>
          </div>
        </div>
      )}

      {!complete && (
        <form
          method="post"
          action="https://www.tfaforms.com/api_v2/workflow/processor"
        >
          <div className="row lg_mt-0 mt-0 general-form align-center medium-align-left">
            <div className="columns small-12 large-6">
              <label className="full input_label" htmlFor="tfa_1">
                First name
              </label>
              <input
                required
                id="tfa_1"
                name="tfa_1"
                type="text"
                placeholder="First name"
                pattern="[A-Za-z]+"
                minlength="2"
                title="Please fill out this field"
                onInvalid={e => {
                  e.target.setCustomValidity("Please fill out this field")
                }}
                onInput={e => e.target.setCustomValidity("")}
                onKeyPress={e => {
                  if (!/[a-zA-Z]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
              />
            </div>

            <div className="columns small-12 large-6 mt-0">
              <label className="full input_label" htmlFor="tfa_2">
                Last name
              </label>
              <input
                required
                id="tfa_2"
                name="tfa_2"
                type="text"
                placeholder="Last name"
                pattern="[A-Za-z]+"
                minlength="2"
                title="Please fill out this field"
                onInvalid={e => {
                  e.target.setCustomValidity("Please fill out this field")
                }}
                onInput={e => e.target.setCustomValidity("")}
                onKeyPress={e => {
                  if (!/[a-zA-Z]/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
              />
            </div>

            <div className="columns small-12 large-6 lg_mt-1 mt-0">
              <label className="full input_label" htmlFor="tfa_4">
                Email
              </label>
              <input
                required
                id="tfa_4"
                name="tfa_4"
                type="email"
                placeholder="Email"
              />
            </div>

            <div className="columns small-12 large-6 lg_mt-1 mt-0">
              <label className="full input_label" htmlFor="tfa_5">
                Phone number
              </label>
              <InputMask
                type="tel"
                id="tfa_5"
                name="tfa_5"
                value={phone}
                mask="999-999-9999"
                pattern="\d{3}-\d{3}-\d{4}"
                maskchar=" "
                onChange={event => setPhone(event.target.value)}
                onKeyDown={event => setPhone(event.target.value)}
                placeholder="Phone number"
                required
              />
            </div>

            <div className="columns small-12 large-6 lg_mt-1 mt-0">
              <label className="full input_label" htmlFor="tfa_6">
                NPI # (optional)
              </label>
              <div className="spinner-hider">
                <input
                  id="tfa_6"
                  name="tfa_6"
                  type="text" // Use type="text" to avoid issues with leading zeros
                  placeholder="NPI #"
                  maxLength="10"
                  onKeyPress={e => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault() // Prevent non-numeric characters from being typed
                    }
                  }}
                  onInput={e => {
                    const input = e.target.value
                    if (input && input.length < 10) {
                      e.target.setCustomValidity(
                        "NPI number must be 10 digits."
                      )
                    } else {
                      e.target.setCustomValidity("") // Clear the custom validity message when valid
                    }
                  }}
                  onInvalid={e => {
                    if (e.target.value) {
                      e.target.setCustomValidity(
                        "NPI number must be 10 digits."
                      )
                    }
                  }}
                />
              </div>
              <a
                href="https://npiregistry.cms.hhs.gov/"
                rel="noreferrer"
                target="_blank"
                className="lg_mt-1 mt-1 underborder color--purple"
                style={{ display: `inline-block` }}
              >
                Look Up Your NPI #
              </a>
            </div>

            <div className="columns small-12 large-6 lg_mt-1 mt-0">
              <label className="full input_label" htmlFor="tfa_7">
                ZIP code
              </label>
              <input
                required
                id="tfa_7"
                name="tfa_7"
                type="text"
                maxLength="5"
                placeholder="ZIP code"
                pattern="\d{5}"
                inputMode="numeric"
                onInput={e => {
                  // Remove any non-numeric characters
                  e.target.value = e.target.value.replace(/[^0-9]/g, "")
                }}
              />
            </div>

            <div className="columns small-12 large-6 lg_mt-0 mt-0">
              <label className="full input_label" htmlFor="tfa_8">
                Select a speciality
              </label>
              <div className="select-wrapper">
                <div className="tick">
                  <FontAwesomeIcon
                    className="color--purple"
                    icon={faAngleDown}
                  />
                </div>
                <select
                  required
                  id="tfa_8"
                  name="tfa_8"
                  // defaultValue={"Select a speciality"}
                  value={choice}
                  title="Speciality"
                  onChange={e => setChoice(e.target.value)}
                >
                  <option selected value="" disabled>
                    Select a speciality
                  </option>
                  <option id="tfa_9" value="tfa_9">
                    Physician/NP – Hematology Oncology
                  </option>
                  <option id="tfa_10" value="tfa_10">
                    Physician/NP – Allergy Immunology
                  </option>
                  <option id="tfa_11" value="tfa_11">
                    Physician/NP – Dermatology
                  </option>
                  <option id="tfa_12" value="tfa_12">
                    Physician/NP – Gastroenterology
                  </option>
                  <option id="tfa_13" value="tfa_13">
                    Nurse
                  </option>
                  <option id="tfa_14" value="tfa_14">
                    Physician Assistant
                  </option>
                  <option id="tfa_15" value="tfa_15">
                    Pharmacist
                  </option>
                  <option id="tfa_16" value="tfa_16">
                    Other Healthcare Professional
                  </option>
                </select>
              </div>
            </div>

            <div className="columns small-12 medium-12 lg_mt-3 mt-2 relative">
              <label htmlFor="tfa_17" className="agreement bodyP">
                <span className="color--purple ">
                  By checking this box, I confirm that I am a US healthcare
                  professional and agree to receive information about systemic
                  mastocytosis and current approved products of Blueprint
                  Medicines related to systemic mastocytosis. I can opt out from
                  such communications by replying “Unsubscribe” to any email.
                  <br />
                  <br />
                  By clicking SIGN UP, I acknowledge that I have read and agree
                  to Blueprint Medicines'{" "}
                  <a
                    href="https://www.blueprintmedicines.com/terms-of-use/"
                    className="underline purple no-ext"
                    target="_blank"
                  >
                    Terms of Use
                  </a>
                  .
                  <br />
                  <br />
                  To learn more about Blueprint Medicines’ privacy practices,
                  including how we process, share, and protect your Personal
                  Information, please read our{" "}
                  <a
                    href="https://www.blueprintmedicines.com/privacy-notice/"
                    className="underline purple no-ext"
                    target="_blank"
                  >
                    Privacy Notice
                  </a>
                  .
                </span>
              </label>
              <input
                className="agreement-box"
                ref={checkboxRef}
                id="tfa_17"
                name="tfa_17"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
                required
              />
              <div className="checker">
                <FontAwesomeIcon className="color--powder" icon={faCheck} />
              </div>
            </div>
            <input
              type="hidden"
              value="5020344"
              name="tfa_dbFormId"
              id="tfa_dbFormId"
            />
            <input
              type="hidden"
              value=""
              name="tfa_dbResponseId"
              id="tfa_dbResponseId"
            />
            <input
              type="hidden"
              value="7cdf5492b5bd43f37118d3ad4ed27e19"
              name="tfa_dbControl"
              id="tfa_dbControl"
            />
            <input
              type="hidden"
              value=""
              name="tfa_dbWorkflowSessionUuid"
              id="tfa_dbWorkflowSessionUuid"
            />
            <input
              type="hidden"
              value="1"
              name="tfa_dbVersionId"
              id="tfa_dbVersionId"
            />
            <input
              type="hidden"
              value=""
              name="tfa_switchedoff"
              id="tfa_switchedoff"
            />
            <div style={{ display: `none`, visibility: `hidden` }}>
              <input
                type="checkbox"
                value="tfa_18"
                className=""
                checked
                data-default-value="true"
                id="tfa_18"
                name="tfa_18"
                aria-labelledby="tfa_18-L"
                data-tfa-labelledby="tfa_17-L tfa_18-L"
              />
              <input
                type="checkbox"
                value="tfa_22"
                className=""
                checked
                data-default-value="true"
                id="tfa_22"
                name="tfa_22"
                aria-labelledby="tfa_22-L"
                data-tfa-labelledby="tfa_21-L tfa_22-L"
              />
              <input
                type="checkbox"
                value="tfa_20"
                className=""
                checked
                data-default-value="true"
                id="tfa_20"
                name="tfa_20"
                aria-labelledby="tfa_20-L"
                data-tfa-labelledby="tfa_19-L tfa_20-L"
              />
              <input
                type="text"
                id="tfa_24"
                name="tfa_24"
                value="SuspectSM.com"
                default="SuspectSM.com"
                title="Lead Source"
                className=""
              />
              <input
                type="hidden"
                id="tfa_25"
                name="tfa_25"
                value="https://suspectsm-staging.azurewebsites.net/sign-up/#complete"
                className=""
              ></input>
            </div>
            <div className="columns small-12 large-12 lg_mt-2">
              <button
                type="submit"
                className="btn btn--mustard btn--small-fw uppercase mt-1 no-max-width sign-up-button"
              >
                Sign up <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
          </div>
        </form>
      )}

      <br />
      <QuestionsSM
        hrImage={hrImage}
        text="Have questions about SM?"
        QuestionsSMBlockButtons={QuestionsSMBlockButtons}
      />
      <br />
      <FooterCrown type="home" />
    </Layout>
  )
}

export default SignUpPage
